<template>
	<div class="page">
		<div class="title">
			<p>{{lang==6? '环境保护': 'CORPORATE SOCIAL RESPONSIBILITY'}}</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="text textEN" v-if="lang!=6">
				<b class="b">environmental protection</b><br>
				Volkswagen Group, a global leader who has always made individual and affordable mobility possible for
				millions of people, is aiming to be a model company in the field of the environment and pursuing to make
				mobility sustainable for us and for the future generations. PAIG China as a part of Volkswagen Group and
				Porsche Holding, <a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/PAIG%20Sustainability%20Statement.pdf"
					target="_blank">PAIG Sustainability Statement</a> details our commitment towards sustainability.
				guided by <a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/mission_statement_environment.pdf"
					target="_blank">Mission Statement Environment</a>, we also
				commit to comply with<a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/group_environmental_policy_statement.pdf"
					target="_blank"> Group Environmental Policy Statement</a> and <a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/new_corporate_mission_statement_environment.pdf"
					target="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/new_corporate_mission_statement_environment.pdf">New
					Corporate Mission Statement
					Environment </a> published by Volkswagen
				Group, to minimize the impact on the environment and to become an environmental role model.
			</div>
			<div class="text" v-if="lang==6">
				<b>环境保护</b><br>
				大众集团，作为全球领先的、为数以千万计的人提供出行服务的服务提供商，致力于为我们和未来几代人提供可持续的智慧出行服务并成为环境保护领域的典范。宝爱捷中国作为大众集团和保时捷控股的一部分，我们对可持续发展的承诺请见
				<a href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%AE%9D%E7%88%B1%E6%8D%B7%E5%8F%AF%E6%8C%81%E7%BB%AD%E5%8F%91%E5%B1%95%E5%A3%B0%E6%98%8E.pdf"
					target="_blank">《宝爱捷可持续发展声明》</a>，我们亦会在<a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E7%8E%AF%E4%BF%9D%E4%BD%BF%E5%91%BD%E5%AE%A3%E8%A8%80.pdf"
					target="_blank">《环保使命宣言》</a>的指引下，遵守<a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E9%9B%86%E5%9B%A2%E7%8E%AF%E5%A2%83%E6%94%BF%E7%AD%96%E5%A3%B0%E6%98%8E.pdf"
					target="_blank">《集团环境政策声明》</a>和大众集团发布的<a
					href="https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%85%A8%E6%96%B0%E4%BC%81%E4%B8%9A%E7%8E%AF%E4%BF%9D%E5%AE%97%E6%97%A8.pdf"
					target="_blank">《全新企业环保宗旨》</a>，努力减少对环境的影响，成为环保领域的榜样。
			</div>
		</div>
		<video v-if="video" :src="video" poster="../assets/EnvironmentVideoBG.png" controls></video>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				video: ''
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 16
				}).then(res => {
					this.video = res.data.video
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 50px;
		font-size: 18px;
		line-height: 0;
	}

	video {
		height: auto;
		width: 100%;
		margin-top: 50px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 0;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		padding: 0 111px 0;
	}

	.b {
		font-weight: bold;
		font-size: 24px;
		text-transform: uppercase;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	ul {
		margin-left: 15px;
	}

	.text {
		padding: 0px 0px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;

		a {
			color: #97233F;
			cursor: pointer;
			text-decoration: none;
		}
	}

	.textEN {
		.b {
			margin-top: 30px;
			line-height: 46px;
			font-weight: bold;
			font-size: 22px;
		}
	}
</style>